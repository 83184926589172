import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["confirm", "additionalConfirm", "submit"]
  static classes = ["button"]

  toggle() {
    // 確認しましたをチェックしているか
    if (this.confirmTarget.checked) {
      // 追加でチェックが必要な項目が存在するか
      if (this.hasAdditionalConfirmTarget) {
        // 追加でチェックが必要な項目をチェックしているか
        if (this.additionalConfirmTarget.checked) {
          this.activate()
        } else {
          this.disable()
        }
      } else {
        this.activate()
      }
    } else {
      this.disable()
    }
  }

  activate() {
    this.submitTarget.classList.remove(this.buttonClass)
  }

  disable() {
    this.submitTarget.classList.add(this.buttonClass)
  }
}
