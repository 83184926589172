import { Controller } from "stimulus"

const activeClass = 'active'
const disactiveClass = 'close'

export default class extends Controller {
  connect() {
    // FIXME: 初回にアクセス時にサイドメニューが閉じてしまう状態になるので一旦コメントにする
    // this.isOpen() ? this.open() : this.close()
  }

  toggle() {
    if (sidebar.classList.contains(activeClass)) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    sidebar.classList.remove(activeClass)
    content.classList.remove(activeClass)
    this.save(activeClass)
  }

  close() {
    sidebar.classList.add(activeClass)
    content.classList.add(activeClass)
    this.save(disactiveClass)
  }

  save(status) {
    localStorage.setItem('sidebar', status);
  }

  isOpen() {
    const status = localStorage.getItem('sidebar')
    return status == activeClass ? true : false
  }
}
