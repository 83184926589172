import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['currentIcon', 'iconUrl', 'select', 'preview', 'input']
  static values = { url: String }

  changeIcon(event) {
    this.removeActive()
    event.target.parentNode.classList.add('active');
    this.currentIconTarget.src = event.target.src
    this.iconUrlTarget.value = event.target.src
  }

  removeActive() {
    this.selectTargets.forEach(function (elem) {
      elem.parentNode.classList.remove('active');
    })
    // カスタム画像のクラスもクリアする
    this.previewTarget.classList.remove('active')
  }

  selectCustomIcon() {
    this.inputTarget.click()
  }

  readURL() {
    var input = this.inputTarget
    // モーダル背後のプレビュー
    var output = this.currentIconTarget
    // モーダル表示上のプレビュー
    var modalPreview = this.previewTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        // !!! 2箇所にセットする !!!
        // モーダル表示上のプレビュー
        var img = document.createElement('img')
        // 画像パスを追加
        img.src = reader.result
        // Stimulusのアクションを追加
        img.dataset.action = 'click->user-icon#addActiveToCustomIcon'
        // 生成したimg要素を追加する
        modalPreview.innerHTML = ''
        modalPreview.appendChild(img)

        // モーダル背後のプレビュー
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }

    this.removeActive()
    this.previewTarget.classList.add('active')
    this.iconUrlTarget.value = ''
  }

  addActiveToCustomIcon(event) {
    this.removeActive()
    event.target.parentNode.classList.add('active');
    this.currentIconTarget.src = event.target.src
    this.iconUrlTarget.value = ''
  }
}
