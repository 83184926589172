import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'prefecture',
    'oitaPrefecture',
    'city',
    'jobType',
    'studentForm',
    'societyForm',
    'jinzaiForm',
    'jinzaiFormLabel'
  ]
  static values = { jobType: String, jinzaiRegistration: String }
  static classes = ['hidden', 'required', 'optional']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    this.requiredclass = this.hasRequiredClass ? this.requiredClass : 'required'
    this.optionalclass = this.hasOptionalClass ? this.OptionalClass : 'optional'
  }

  toggleWithJobType(e) {
    if (e.target.value == this.jobTypeValue) {
      this.studentFormTargets.forEach(item => {
        item.classList.remove(this.class)
      })
      this.societyFormTargets.forEach(item => {
        item.classList.add(this.class)
      })
    } else {
      this.studentFormTargets.forEach(item => {
        item.classList.add(this.class)
      })
      this.societyFormTargets.forEach(item => {
        item.classList.remove(this.class)
      })
    }
  }

  toggleWithjinzaiRegistration(e) {
    if (e.target.value == this.jinzaiRegistrationValue) {
      this.jinzaiFormTargets.forEach(item => {
        item.classList.remove(this.class)
      })
      this.jinzaiFormLabelTargets.forEach(item => {
        item.classList.add(this.requiredClass)
        item.classList.remove(this.optionalClass)
      })
    } else {
      this.jinzaiFormTargets.forEach(item => {
        item.classList.add(this.class)
      })
      this.jinzaiFormLabelTargets.forEach(item => {
        item.classList.remove(this.requiredClass)
        item.classList.add(this.optionalClass)
      })
    }
  }

  // 都道府県を他の要素にコピーする
  fetchPrefecture(e) {
    const prefecture = this.prefectureTarget
    prefecture.innerHTML = '';
    const opt = document.createElement('option');
    opt.value = e.target.value;
    opt.innerHTML = e.target.options[e.target.selectedIndex].text;
    prefecture.appendChild(opt);
    this.cityTarget.innerHTML = '';
    const defaultOpt = document.createElement('option');
    defaultOpt.innerHTML = '選択してください';
    this.cityTarget.appendChild(defaultOpt)
  }

  // 市区町村を他の要素にコピーする
  fetchCity(e) {
    const city = this.cityTarget
    city.innerHTML = '';
    const opt = document.createElement('option');
    opt.value = e.target.value;
    opt.innerHTML = e.target.options[e.target.selectedIndex].text;
    city.appendChild(opt);
  }

  // 地域が大分を選択した時と、そうできない時の動作を追加
  fetchOitaPrefecture(e) {
    if ( e.target.value == '44'){
      // 地域が大分を選択した際、人材センターの誘導を非表示かつ、登録しないを選択する。
      this.oitaPrefectureTargets.forEach(item => {
        item.classList.add(this.class)
      })
      const jinzaicenter_status = document.getElementById("user_jinzaicenter_status_unregister");
      jinzaicenter_status.checked = true
    } else {
      // 上記以外を選択した際、人材センターの誘導を表示かつ、登録するを選択する。
      this.oitaPrefectureTargets.forEach(item => {
        item.classList.remove(this.class)
      })
      const jinzaicenter_status = document.getElementById("user_jinzaicenter_status_register");
      jinzaicenter_status.checked = true
    }
  }
}
