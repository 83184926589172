// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Remote from "stimulus-remote-rails"
import NestedForm from "stimulus-rails-nested-form"
import Autosave from "stimulus-rails-autosave"
import Reveal from "stimulus-reveal-controller"
import ReadMore from "stimulus-read-more"
import Chart from "stimulus-chartjs"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("remote", Remote)
application.register("nested-form", NestedForm)
application.register("autosave", Autosave)
application.register("reveal", Reveal)
application.register("read-more", ReadMore)
application.register("chart", Chart)
