// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
// import * as ActiveStorage from "@rails/activestorage"
import 'channels';

Rails.start();
Turbolinks.start();
// ActiveStorage.start()

require('jquery');

// Bootstrap5
import 'bootstrap';
// Fontawesome
import '@fortawesome/fontawesome-free/js/all';
global.FontAwesome.config.mutateApproach = 'sync';

import 'controllers';
import '../stylesheets/application';

const images = require.context('../images/', true);

// ブラウザバックでckeditorが複数表示されてしまうことの対策
document.addEventListener('turbolinks:before-cache', function () {
  if (window.editor && document.querySelector('.js-editor')) {
    window.editor.destroy();
  }
});

// TOPページのブラウザバックで、キーワード選択のスタイルが外れてしまう現象を回避
window.onpageshow = function () {
  // ページ読み込み時
  const keyword_tag = document.getElementsByName('q[profile_tags_id_in][]');
  for (let i = 0; i < keyword_tag.length; i++) {
    // labelやinputに使われる名前を作成
    var name = 'q_profile_tags_id_in_' + keyword_tag[i].value;
    if (keyword_tag[i].checked) {
      // キーワードがチェックされていれば、親要素にスタイルを加える
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'inline-block');
      $(`label[for=${name}]`).parent().addClass('active');
    } else {
      // キーワードがチェックされていなければ、親要素にスタイルを外す
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
      $(`label[for=${name}]`).parent().removeClass('active');
    }
  }
};

$(document).on('turbolinks:load', function () {
  // キーワード選択時の挙動
  // name属性"q[profile_tags_id_in][]"の要素を複数取得する
  const keyword_tag = document.getElementsByName('q[profile_tags_id_in][]');

  // ページ読み込み時
  for (let i = 0; i < keyword_tag.length; i++) {
    // labelやinputに使われる名前を作成
    var name = 'q_profile_tags_id_in_' + keyword_tag[i].value;
    if (keyword_tag[i].checked) {
      // キーワードがチェックされていれば、親要素にスタイルを加える
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'inline-block');
      $(`label[for=${name}]`).parent().addClass('active');
    } else {
      // キーワードがチェックされていなければ、親要素にスタイルを外す
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
      $(`label[for=${name}]`).parent().removeClass('active');
    }
  }
  // タグ選択の確定ボタン
  $('#tag-submit').on('click', function () {
    const keyword_tag = document.getElementsByName('q[profile_tags_id_in][]');
    for (let i = 0; i < keyword_tag.length; i++) {
      var name = 'q_profile_tags_id_in_' + keyword_tag[i].value;
      if (keyword_tag[i].checked) {
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'inline-block');
        $(`label[for=${name}]`).parent().addClass('active');
      } else {
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
        $(`label[for=${name}]`).parent().removeClass('active');
      }
    }
  });

  // タグ選択のキャンセル
  $('#tag-cancel').on('click', function () {
    const keyword_tag = document.getElementsByName('q[profile_tags_id_in][]');
    for (let i = 0; i < keyword_tag.length; i++) {
      {
        var name = 'q_profile_tags_id_in_' + keyword_tag[i].value;
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
        $(`label[for=${name}]`).parent().removeClass('active');
        keyword_tag[i].checked = false;
        $('.select-keyword').removeClass('active');
      }
    }
  });

  // キーワード選択を押した時の動作
  $('#select_keyword-button').on('click', function () {
    const keyword_tag = document.getElementsByName('q[profile_tags_id_in][]');
    for (let i = 0; i < keyword_tag.length; i++) {
      {
        var name = 'q_profile_tags_id_in_' + keyword_tag[i].value;
        if (keyword_tag[i].checked) {
          $(`label[for=${name}]`).css('display', 'inline-block');
          $(`label[for=${name}]`).parent().addClass('active');
        } else {
          $(`label[for=${name}]`).css('display', 'inline-block');
          $(`label[for=${name}]`).parent().removeClass('active');
        }
      }
    }
  });

  //インターン用キーワード選択（インターンとその他でｊｓを別にしているので１つにするようにリファクタリングする）
  const keyword_tag1 = document.getElementsByName('q[tags_id_in][]');

  // ページ読み込み時
  for (let i = 0; i < keyword_tag1.length; i++) {
    // labelやinputに使われる名前を作成
    var name = 'q_tags_id_in_' + keyword_tag1[i].value;
    if (keyword_tag1[i].checked) {
      // キーワードがチェックされていれば、親要素にスタイルを加える
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'inline-block');
      $(`label[for=${name}]`).parent().addClass('active');
    } else {
      // キーワードがチェックされていなければ、親要素にスタイルを外す
      $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
      $(`label[for=${name}]`).parent().removeClass('active');
    }
  }
  // タグ選択の確定ボタン
  $('#tag-submit').on('click', function () {
    const keyword_tag1 = document.getElementsByName('q[tags_id_in][]');
    for (let i = 0; i < keyword_tag1.length; i++) {
      var name = 'q_tags_id_in_' + keyword_tag1[i].value;
      if (keyword_tag1[i].checked) {
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'inline-block');
        $(`label[for=${name}]`).parent().addClass('active');
      } else {
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
        $(`label[for=${name}]`).parent().removeClass('active');
      }
    }
  });

  // タグ選択のキャンセル
  $('#tag-cancel').on('click', function () {
    const keyword_tag1 = document.getElementsByName('q[tags_id_in][]');
    for (let i = 0; i < keyword_tag1.length; i++) {
      {
        var name = 'q_tags_id_in_' + keyword_tag1[i].value;
        $(`input[id=${name}]`).parent().parent().parent().css('display', 'none');
        $(`label[for=${name}]`).parent().removeClass('active');
        keyword_tag1[i].checked = false;
        $('.select-keyword').removeClass('active');
      }
    }
  });

  // キーワード選択を押した時の動作
  $('#select_keyword-button').on('click', function () {
    const keyword_tag1 = document.getElementsByName('q[tags_id_in][]');
    for (let i = 0; i < keyword_tag1.length; i++) {
      {
        var name = 'q_tags_id_in_' + keyword_tag1[i].value;
        if (keyword_tag1[i].checked) {
          $(`label[for=${name}]`).css('display', 'inline-block');
          $(`label[for=${name}]`).parent().addClass('active');
        } else {
          $(`label[for=${name}]`).css('display', 'inline-block');
          $(`label[for=${name}]`).parent().removeClass('active');
        }
      }
    }
  });

  // モーダルのキーワードタグスタイル切り替え
  $('.select-keyword').click(function () {
    $(this).toggleClass('active');
  });
  // 検索ページのキーワードタグスタイル切り替え
  $('.keyword input').on('click', function () {
    $(this).parent().parent().parent().toggleClass('active');
  });

  // TOPページ、求人をさがす、キーワード選択
  var flg = 'close';
  $('#more-checktag').on('click', function () {
    $('.form-check_show').toggleClass('active-more');
    if (flg == 'close') {
      $(this).text('省略する');
      flg = 'open';
    } else {
      $(this).text('もっと見る');
      flg = 'close';
    }
  });

  //スライダー
  $('.slider').not('.slick-initialized').slick({
    dots: true,
    dotsClass: 'slide-dots',
  });

  //モーダル
  $('#modal-open').on('click', function () {
    $('.main-modal').addClass('open');
    $('#sidebar').addClass('open');
    $('#modal-open__footer').addClass('close');
    $('#modal-close__footer').addClass('open');
  });

  $('#modal-close').on('click', function () {
    $('.main-modal').removeClass('open');
    $('#sidebar').removeClass('open');
    $('#modal-close__footer').removeClass('open');
    $('#modal-open__footer').removeClass('close');
  });

  $('#modal-open__footer').on('click', function () {
    $('.main-modal').addClass('open');
    $('#sidebar').addClass('open');
    $(this).addClass('close');
    $('#modal-close__footer').addClass('open');
  });

  $('#modal-close__footer').on('click', function () {
    $('.main-modal').removeClass('open');
    $('#sidebar').removeClass('open');
    $(this).removeClass('open');
    $('#modal-open__footer').removeClass('close');
  });

  //画像切り替え
  $('.image-list__item:first').addClass('first-view-active');
  $('.image-list__item').on('click', function () {
    $('#first-view').html($(this).html());
    $('.image-list__item').removeClass('first-view-active');
    $(this).addClass('first-view-active');
  });

  var widthFlag = '';
  $(function () {
    // 画面サイズのチェック
    $(window).on('load resize', function () {
      widthCheck();
    });
  });

  // 画面サイズのチェック
  function widthCheck() {
    // 画面幅取得
    var winWidth = $(window).width();

    // 画面幅640以下でフラグがspでない時
    if (winWidth <= 1065 && widthFlag != 'middle') {
      // フラグをSPに変更
      widthFlag = 'middle';
      $('#content').addClass('active');
      $('#sidebar').addClass('active');
      // 画面幅640よりおおきく、フラグがpcでない時
    } else if (winWidth > 1065 && widthFlag != 'pc') {
      // フラグをPCに変更
      widthFlag = 'pc';
      $('#content').removeClass('active');
      $('#sidebar').removeClass('active');
    }
  }
  // 通知オンオフ
  $('#notification-modal').on('click', function () {
    $('#notification-content').toggleClass('open');
  });

  //　マイページ右上設定のモーダルオンオフ
  $('#setting-modal').on('click', function () {
    $('#setting-content').toggleClass('open');
  });

  $('#alert-content').delay(3000).hide(1000);

  //企業詳細ページにて、特定のスクロール地点でclass出し入れする
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 500) {
      $('#company-content-fixed').show();
      return;
    } else {
      $('#company-content-fixed').hide();
      return;
    }
  });
});

// turbolinksの影響でページ遷移時にスクロール位置を保持するのを防ぐ
window.addEventListener('turbolinks:load', function () {
  document.querySelector('html').style.scrollBehavior = 'smooth';
});
window.addEventListener('turbolinks:before-visit', function () {
  document.querySelector('html').style.scrollBehavior = 'unset';
});
