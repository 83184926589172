import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "preview", "start", "end"]

  connect() {
    this.preview()
  }

  preview() {
    this.previewTarget.innerHTML = autoLink(this.contentTarget.value)
  }

  // 終了時刻に1時間足した時刻をセットして、
  // 開始時刻より前を選択できないようにする
  setEndFromStart() {
    this.endTarget.value = adjustTime(this.startTarget.value, 1);
    this.endTarget.min = adjustTime(this.startTarget.value, 0);
  }
}

function adjustTime(start, addingHour = 0) {
  let dt = new Date(start);
  dt.setHours(dt.getHours() + addingHour);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()); // これをしないと9時間ずれる
  return dt.toISOString().slice(0, -1);
}

function autoLink(text) {
  if (typeof(text) === "string") {
    return text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>").replace(/\n/g, '<br>')
  } else {
    return ''
  }
}