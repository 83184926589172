import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "target"];

  handleSelectChange() {
    this.populateSelect(this.sourceTarget.value);
  }

  populateSelect(sourceId, targetId = null) {
    // サブミットボタンを押せないようにする
    const submitBtns = document.querySelectorAll('form [type=submit]')
    submitBtns.forEach(btn => {
      btn.disabled = true
    })
    fetch(`/${this.data.get('url')}?${this.data.get('parameter')}=${sourceId}`, {
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(data => {
        const selectBox = this.targetTarget;
        selectBox.innerHTML = '';
        selectBox.appendChild(this.defaultOption())
        data.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item[this.data.get('valueAttribute')];
          opt.innerHTML = item[this.data.get('displayAttribute')];
          opt.selected = parseInt(targetId) === item[this.data.get('valueAttribute')];
          selectBox.appendChild(opt);
        });
        // サブミットボタンを押せるようにする
        submitBtns.forEach(btn => {
          btn.disabled = false
        })
      });
  }

  defaultOption() {
    const defaultOpt = document.createElement('option');
    defaultOpt.innerHTML = '選択してください'
    defaultOpt.value = ''
    return defaultOpt
  }
}
