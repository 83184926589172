import { Controller } from "stimulus"

export default class extends Controller {
  validate(event) {
    let file = event.target.files[0]
    let name = file.name
    let size = file.size
    let type = file.type
    let errors = ''

    //上限サイズは5MB
    if (size > 5000000) {
      errors += 'ファイルの上限サイズ5MBを超えています\n'
    }

    //拡張子は .jpg .png .jpeg .gif のみ許可
    if (type != 'image/jpeg' && type != 'image/gif' && type != 'image/png') {
      errors += '.jpg、.gif、.pngのいずれかのファイルのみ許可されています\n'
    }

    if (errors) {
      //errorsが存在する場合は内容をalert
      alert(errors)
      //valueを空にしてリセットする
      event.currentTarget.value = ''
    }
  }
}
